@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');
$svg: (dashboard: "%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.448' height='17.448' viewBox='0 0 17.448 17.448'%3E%3Cpath id='Icon_material-dashboard' data-name='Icon material-dashboard' d='M4.5,14.194h7.755V4.5H4.5Zm0,7.755h7.755V16.132H4.5Zm9.694,0h7.755V12.255H14.194Zm0-17.448v5.816h7.755V4.5Z' transform='translate(-4.5 -4.5)' fill='%2301b5d8'/%3E%3C/svg%3E%0A",
  claimlist: "%3Csvg id='Claim_Menu' data-name='Claim Menu' xmlns='http://www.w3.org/2000/svg' width='17.44' height='23.253' viewBox='0 0 17.44 23.253'%3E%3Cpath id='Icon_awesome-clipboard-list' data-name='Icon awesome-clipboard-list' d='M15.26,2.907H11.626a2.907,2.907,0,0,0-5.813,0H2.18A2.181,2.181,0,0,0,0,5.087V21.073a2.181,2.181,0,0,0,2.18,2.18H15.26a2.181,2.181,0,0,0,2.18-2.18V5.087A2.181,2.181,0,0,0,15.26,2.907ZM4.36,19.256a1.09,1.09,0,1,1,1.09-1.09A1.087,1.087,0,0,1,4.36,19.256Zm0-4.36a1.09,1.09,0,1,1,1.09-1.09A1.087,1.087,0,0,1,4.36,14.9Zm0-4.36a1.09,1.09,0,1,1,1.09-1.09A1.087,1.087,0,0,1,4.36,10.536Zm4.36-8.72a1.09,1.09,0,1,1-1.09,1.09A1.087,1.087,0,0,1,8.72,1.817ZM14.533,18.53a.364.364,0,0,1-.363.363H7.63a.364.364,0,0,1-.363-.363V17.8a.364.364,0,0,1,.363-.363h6.54a.364.364,0,0,1,.363.363Zm0-4.36a.364.364,0,0,1-.363.363H7.63a.364.364,0,0,1-.363-.363v-.727a.364.364,0,0,1,.363-.363h6.54a.364.364,0,0,1,.363.363Zm0-4.36a.364.364,0,0,1-.363.363H7.63a.364.364,0,0,1-.363-.363V9.083A.364.364,0,0,1,7.63,8.72h6.54a.364.364,0,0,1,.363.363Z' fill='%23337dff'/%3E%3C/svg%3E",
  checklist: "%3Csvg id='TaskList-menu' xmlns='http://www.w3.org/2000/svg' width='17.44' height='23.253' viewBox='0 0 17.44 23.253'%3E%3Cpath id='Icon_awesome-clipboard-check' data-name='Icon awesome-clipboard-check' d='M15.26,2.907H11.626a2.907,2.907,0,0,0-5.813,0H2.18A2.181,2.181,0,0,0,0,5.087V21.073a2.181,2.181,0,0,0,2.18,2.18H15.26a2.181,2.181,0,0,0,2.18-2.18V5.087A2.181,2.181,0,0,0,15.26,2.907ZM8.72,1.817a1.09,1.09,0,1,1-1.09,1.09A1.087,1.087,0,0,1,8.72,1.817Zm5.5,10.527L7.73,18.784a.545.545,0,0,1-.772,0L3.206,15a.545.545,0,0,1,0-.772L4.5,12.944a.546.546,0,0,1,.772,0l2.089,2.107,4.814-4.778a.545.545,0,0,1,.772,0l1.281,1.29A.545.545,0,0,1,14.224,12.344Z' fill='%2394c85f'/%3E%3C/svg%3E%0A",
  map-view: "%3Csvg id='Map-menu' xmlns='http://www.w3.org/2000/svg' width='18' height='15.983' viewBox='0 0 18 15.983'%3E%3Cpath id='Icon_awesome-map-marked-alt' data-name='Icon awesome-map-marked-alt' d='M8.99,0A3.933,3.933,0,0,0,5.057,3.933c0,1.756,2.57,4.957,3.555,6.119a.492.492,0,0,0,.755,0c.985-1.162,3.555-4.363,3.555-6.119A3.933,3.933,0,0,0,8.99,0Zm0,5.245A1.311,1.311,0,1,1,10.3,3.933,1.311,1.311,0,0,1,8.99,5.245ZM.628,6.741A1,1,0,0,0,0,7.669v7.814a.5.5,0,0,0,.685.464l4.309-1.962V6.709A9.454,9.454,0,0,1,4.331,5.26ZM8.99,11.228A1.491,1.491,0,0,1,7.85,10.7C7.236,9.974,6.584,9.15,5.993,8.3v5.682l5.993,2V8.3c-.591.846-1.243,1.671-1.857,2.395A1.492,1.492,0,0,1,8.99,11.228Zm8.3-6.2L12.985,6.993v8.991l4.366-1.747a1,1,0,0,0,.628-.927V5.495A.5.5,0,0,0,17.294,5.031Z' transform='translate(0 0)' fill='%23337dff'/%3E%3Cpath id='Path_133' data-name='Path 133' d='M14.393,7.762l4.641-2.078s.859-.562.906.406,0,8.641,0,8.641.125.688-.766,1.078-4.781,1.906-4.781,1.906Z' transform='translate(-1.96 -1.738)' fill='%233c88f6'/%3E%3Cpath id='Path_134' data-name='Path 134' d='M13.283,17.715v-8.5s-2.219,3.172-3.172,3.234S6.768,9.324,6.643,9.215s0,6.3,0,6.3Z' transform='translate(-0.973 -1.741)' fill='%23fad53c'/%3E%3Cpath id='Path_135' data-name='Path 135' d='M5.518,15.527V7.465s-.437-.828-.75-1.625S.455,7.605.455,7.605A1.71,1.71,0,0,0,.018,8.98c.016,1.016,0,8.125,0,8.125s.25.8.891.484' transform='translate(-0.002 -1.741)' fill='%230da25e'/%3E%3C/svg%3E%0A",
  userslist: "%3Csvg id='account-multiple-outline' xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'%3E%3Cg id='Boundary' fill='%23337dff' stroke='rgba(0,0,0,0)' stroke-width='1' opacity='0'%3E%3Crect width='23' height='23' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='22' height='22' fill='none'/%3E%3C/g%3E%3Cpath id='Path_account-multiple-outline' data-name='Path / account-multiple-outline' d='M12.516,9.983a4.6,4.6,0,0,0,0-5.432A3.261,3.261,0,0,1,14.35,4a3.267,3.267,0,1,1,0,6.533,3.261,3.261,0,0,1-1.834-.551M5.325,7.267A3.326,3.326,0,1,1,8.65,10.533,3.3,3.3,0,0,1,5.325,7.267m1.9,0a1.425,1.425,0,1,0,1.425-1.4,1.413,1.413,0,0,0-1.425,1.4M15.3,16.133V18H2V16.133S2,12.4,8.65,12.4s6.65,3.733,6.65,3.733m-1.9,0c-.133-.728-1.264-1.867-4.75-1.867S3.967,15.489,3.9,16.133M15.252,12.4A4.938,4.938,0,0,1,17.2,16.133V18H21V16.133s0-3.388-5.757-3.733Z' transform='translate(0 1)' fill='%236833ff'/%3E%3C/svg%3E%0A",
  carrierlist: "%3Csvg id='domain' xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Crect id='Boundary' width='22' height='22' fill='%2314ffbf' opacity='0'/%3E%3Cpath id='Path_domain' data-name='Path / domain' d='M16.8,14.1h-1.85v1.85H16.8m0-5.549h-1.85v1.85H16.8m1.85,5.549h-7.4v-1.85H13.1V14.1h-1.85v-1.85H13.1V10.4h-1.85V8.549h7.4M9.4,6.7H7.549V4.85H9.4m0,5.549H7.549V8.549H9.4m0,5.549H7.549v-1.85H9.4m0,5.549H7.549v-1.85H9.4M5.7,6.7H3.85V4.85H5.7m0,5.549H3.85V8.549H5.7m0,5.549H3.85v-1.85H5.7m0,5.549H3.85v-1.85H5.7M11.248,6.7V3H2V19.646H20.5V6.7Z' transform='translate(-0.15 -0.465)' fill='%2322ce99'/%3E%3C/svg%3E",
  alert-icon: "%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.027' height='22.027' viewBox='0 0 20.027 22.027'%3E%3Cg id='Icon_feather-bell' data-name='Icon feather-bell' transform='translate(-3.5 -2)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M19.523,9.009a6.009,6.009,0,0,0-12.018,0c0,7.011-3,9.014-3,9.014H22.527s-3-2-3-9.014' fill='none' stroke='%23a0b0e5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='Path_2' data-name='Path 2' d='M18.87,31.5a2,2,0,0,1-3.465,0' transform='translate(-3.624 -9.471)' fill='none' stroke='%23a0b0e5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A",
  settings: "%3Csvg id='Icon_ionic-ios-settings' data-name='Icon ionic-ios-settings' xmlns='http://www.w3.org/2000/svg' width='20.527' height='20.532' viewBox='0 0 20.527 20.532'%3E%3Cpath id='Icon_ionic-ios-settings-2' data-name='Icon ionic-ios-settings' d='M23.332,14.763A2.641,2.641,0,0,1,25.027,12.3,10.47,10.47,0,0,0,23.76,9.247a2.677,2.677,0,0,1-1.074.23,2.636,2.636,0,0,1-2.411-3.71A10.438,10.438,0,0,0,17.228,4.5a2.639,2.639,0,0,1-4.929,0A10.47,10.47,0,0,0,9.247,5.767a2.636,2.636,0,0,1-2.411,3.71,2.59,2.59,0,0,1-1.074-.23A10.7,10.7,0,0,0,4.5,12.3a2.641,2.641,0,0,1,.005,4.929,10.47,10.47,0,0,0,1.267,3.052,2.637,2.637,0,0,1,3.48,3.48A10.531,10.531,0,0,0,12.3,25.032a2.634,2.634,0,0,1,4.918,0,10.47,10.47,0,0,0,3.052-1.267,2.639,2.639,0,0,1,3.48-3.48,10.531,10.531,0,0,0,1.267-3.052A2.654,2.654,0,0,1,23.332,14.763Zm-8.521,4.271a4.276,4.276,0,1,1,4.276-4.276A4.275,4.275,0,0,1,14.812,19.035Z' transform='translate(-4.5 -4.5)' fill='%23a0b0e5'/%3E%3C/svg%3E%0A",
  logo: "%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='36.79' height='17' viewBox='0 0 36.79 17'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_84' data-name='Rectangle 84' width='36.79' height='17' fill='%23971515'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_41' data-name='Group 41' transform='translate(0 0)'%3E%3Cg id='Group_40' data-name='Group 40' transform='translate(0 0)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_17' data-name='Path 17' d='M18.555.008A32.585,32.585,0,0,0,6.01,2.037L25.436,14.832,3.653,3.2C1.391,4.565.036,6.274,0,8.153c-.089,4.69,8.075,8.648,18.234,8.84S36.7,13.539,36.789,8.848,28.714.2,18.555.008' transform='translate(0 0)' fill='%23971515'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A",
  resumes: "%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='21.639' viewBox='0 0 17 21.639'%3E%3Cg id='Group_506' data-name='Group 506' transform='translate(-146.833 -221.434)'%3E%3Cpath id='Path_150' data-name='Path 150' d='M157.651,221.434c0,1.316-.007,2.6,0,3.875a2.291,2.291,0,0,0,1.758,2.233,3.024,3.024,0,0,0,.693.072c1.215.007,2.43,0,3.646.005a.683.683,0,0,1,.083.016v.246q0,6.375,0,12.75a2.319,2.319,0,0,1-2.439,2.442h-12.12a2.321,2.321,0,0,1-2.44-2.441q0-8.379,0-16.758a2.321,2.321,0,0,1,2.442-2.439h8.376Zm-4.643,13.91c.764,0,1.528,0,2.291,0a.767.767,0,0,0,.8-.8c0-.531.01-1.062,0-1.592a2.245,2.245,0,0,0-1.915-2.186,14.44,14.44,0,0,0-2.021-.047,2.243,2.243,0,0,0-2.189,1.911,15.392,15.392,0,0,0-.051,1.853.781.781,0,0,0,.864.867Q151.9,235.346,153.008,235.344Zm2.323-7.724a2.317,2.317,0,1,0-2.322,2.313A2.32,2.32,0,0,0,155.332,227.621Zm-.024,11.588h1.448c1.07,0,2.141,0,3.211,0a.771.771,0,0,0,.156-1.526,1.578,1.578,0,0,0-.312-.017h-8.788c-.121,0-.242-.006-.362,0a.745.745,0,0,0-.683.493.77.77,0,0,0,.767,1.049C152.266,239.211,153.787,239.208,155.308,239.209Zm3.891-7.728c.249,0,.5,0,.748,0a.772.772,0,1,0,.005-1.543q-.76-.009-1.521,0a.771.771,0,1,0-.005,1.542C158.684,231.485,158.942,231.48,159.2,231.48Zm0,3.864c.249,0,.5,0,.748,0a.772.772,0,1,0,.011-1.543q-.76-.009-1.521,0a.771.771,0,1,0-.011,1.542C158.681,235.35,158.939,235.344,159.2,235.344Z' transform='translate(0 0)' fill='%23c171ef'/%3E%3Cpath id='Path_151' data-name='Path 151' d='M338.54,229.032l4.133,4.128c-.123.009-.19.018-.257.018q-1.533,0-3.065,0a.784.784,0,0,1-.866-.867q0-1.533,0-3.066v-.187Z' transform='translate(-179.289 -7.108)' fill='%23c171ef'/%3E%3C/g%3E%3C/svg%3E%0A",
  pdf:"%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.75' height='17' viewBox='0 0 12.75 17'%3E%3Cpath id='Icon_awesome-file-pdf' data-name='Icon awesome-file-pdf' d='M6.04,8.5a3.2,3.2,0,0,1-.066-1.557C6.252,6.946,6.226,8.171,6.04,8.5Zm-.056,1.567a15.321,15.321,0,0,1-.943,2.082,12.226,12.226,0,0,1,2.088-.727A4.3,4.3,0,0,1,5.983,10.071ZM2.859,14.214c0,.027.438-.179,1.159-1.335A4.589,4.589,0,0,0,2.859,14.214Zm5.376-8.9H12.75V16.2a.8.8,0,0,1-.8.8H.8a.8.8,0,0,1-.8-.8V.8A.8.8,0,0,1,.8,0H7.438V4.516A.8.8,0,0,0,8.234,5.313Zm-.266,5.7A3.332,3.332,0,0,1,6.551,9.23,4.772,4.772,0,0,0,6.757,7.1.832.832,0,0,0,5.17,6.873,5.521,5.521,0,0,0,5.439,9.43a31.179,31.179,0,0,1-1.355,2.849s0,0-.007,0c-.9.462-2.444,1.478-1.81,2.258a1.032,1.032,0,0,0,.714.332c.594,0,1.185-.6,2.029-2.052a18.926,18.926,0,0,1,2.623-.77,5.032,5.032,0,0,0,2.125.647.86.86,0,0,0,.654-1.441c-.462-.452-1.8-.322-2.444-.239Zm4.549-7.53L9.264.232A.8.8,0,0,0,8.7,0H8.5V4.25h4.25v-.2A.794.794,0,0,0,12.518,3.486Zm-2.46,8.477c.136-.09-.083-.4-1.421-.3C9.868,12.189,10.057,11.963,10.057,11.963Z' fill='%23d51f1f'/%3E%3C/svg%3E%0A",
);

$pilot-colors: (bg0: #f7f8f9,
  bg1: #ffffff,
  bg2: #337dff,
  bg3: #e1e1e1,
  bg6: #000,
  bg7: #000,
  bggreen: #0adb0a,
  bgyellow: #ffb507,
  bgred: #d5001a,
  bglightgreen: #8fbe6d,
);

$desktop: 1366px;
$medium:1396px;
$base-space: 1rem;

* {
  margin: 0;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

@mixin table-cell {
  display: table-cell;
  padding: 10px 15px;
  vertical-align: middle;
}

@mixin table-compo {
  display: table;
  position: relative;
  border-radius: 5px;
  width: 100%;
}

@mixin icon-grade {
  background-repeat: no-repeat;
  width: 37px;
  height: 37px;
  background-size: 22px;
  display: block;
  background-position: center;
}

@mixin column {
  flex: 0 0 auto;
}

@mixin circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  margin-top: 3px;
}

html,
body,
#root {
  height: 100%;
}

body {
  background-color: map-get($pilot-colors, "bg0");
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;

}

.mb-2 {
  margin-bottom: 2rem;
}

.bg-White {
  background-color: white;
}

.d-block {
  display: block;
}

/*Login Page*/
.login-page {
  height: 100vh;
  display: table !important;

  .login-container {
    display: table-cell;
    vertical-align: middle;
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    left: 15px;
  }
}

/**/
/*MUI style hack*/
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.MuiInputLabel-root {
  left: 16px !important;
}

.MuiBreadcrumbs-ol {
  font-size: 12px;
}

.css-19kzrtu {
  padding: 24px 10px 8px !important
}

.resourcesTab {
  .css-19kzrtu {
    padding: 1rem 0 0 !important
  }
}

/**/

/*Kendo grid style hack*/
// .k-pager-sizes,

.k-grid-header .k-cell-inner > .k-link > .k-icon.k-i-sort-desc-sm, .k-grid-header .k-cell-inner > .k-link > .k-icon.k-i-sort-asc-sm {
  position: absolute;
    left: -8px;
}
.k-pager-info {
  text-transform: capitalize;
}

.k-toolbar {
  background-color: transparent;
}

.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer,
.k-pager-wrap {
  background-color: transparent;
}

.k-grid-header-wrap {
  font-weight: 700;
  color: black;
}

.k-grid tr.k-alt {
  background-color: rgba(0, 0, 0, 0.02);
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: map-get($pilot-colors, "bg2");
  background-color: map-get($pilot-colors, "bg2");
}

.k-grid td.k-state-selected,
.k-grid td.k-selected,
.k-grid tr.k-state-selected>td,
.k-grid tr.k-selected>td {
  background-color: rgb(51 125 255 / 9%) !important;
}

.k-pager-numbers .k-link {
  color: map-get($pilot-colors, "bg2");

  &:hover {
    color: white;
    background-color: map-get($pilot-colors, "bg2");
    border-radius: 50%;
  }
}

.k-pager-numbers .k-link.k-state-selected,
.k-pager-numbers .k-link.k-selected {
  color: white;
  background-color: map-get($pilot-colors, "bg2");
  border-radius: 50%;
}

.k-grid {
  font-size: 15px;
  line-height: 1.5;
}
.k-pager-sizes
.k-dropdownlist{
  width: auto!important;

}
/*Kendo grid style hack*/

/*Grid Search*/
.search-wrap {
  display: flex;
  width: 100%;
  align-items: center;

  >div {
    margin-left: auto;
  }

  .MuiOutlinedInput-input {
    background: url(../images/search.svg) no-repeat right 10px center;
    padding-right: 32px !important;
  }
}

/*Grid Search end*/



b,
h4 {
  color: black;
  margin-bottom: ($base-space/4);
  display: block;
}

.d-flex {
  display: flex !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-1 {
  margin-top: $base-space;
}

.mb-1 {
  margin-bottom: $base-space;
}

.gray-text {
  color: map-get($pilot-colors, "bg6") !important;
}

.align-self-center {
  align-self: center;
}

.m-0 {
  margin: 0 !important;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-100 {
  width: 100%;
}

.basis800 {
  flex-basis: 800px;
}

.red {
  color: map-get($pilot-colors, "bgred");
}

.green {
  color: map-get($pilot-colors, "bggreen");
}

.yellow {
  color: map-get($pilot-colors, "bgyellow");
}

.light-green {
  color: map-get($pilot-colors, "bglightgreen");

  &.hover {
    cursor: pointer;
  }
}

.k-command-cell {
  &.hover {
    cursor: pointer;
  }
}

.icons {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &.absoluteR {
    position: absolute;
    right:15px;
    color: #337dff;
    top:15px;

    svg {
      font-size: 19px;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.red-circle {
  background: map-get($pilot-colors, "bgred");
  @include circle;
}

.green-circle {
  background: map-get($pilot-colors, "bggreen");
  @include circle;
}

.yellow-circle {
  background: map-get($pilot-colors, "bgyellow");
  @include circle;
}

.MuiLinearProgress-colorSuccess {
  margin-top:3px;
  margin-bottom: 3px;
  background-color:#D9D4D4!important;
  .MuiLinearProgress-barColorSuccess {
    background: map-get($pilot-colors, "bggreen")!important;
  }
}

.MuiLinearProgress-colorError {
  margin-top:3px;
  margin-bottom: 3px;
  background-color:#D9D4D4!important;
  .MuiLinearProgress-barColorError {
    background: map-get($pilot-colors, "bgred")!important;
  }
}

.MuiLinearProgress-colorWarning {
  margin-top:3px;
  margin-bottom: 3px;
  background-color:#D9D4D4!important;
  .MuiLinearProgress-barColorWarning {
    background: map-get($pilot-colors, "bgyellow")!important;
  }
}

.text-bold {
  font-weight: 700;
}

.phone {
  background: url("../images/phone-in-talk.svg") no-repeat left top 1px;
  padding-left: 27px !important;
  background-size: 15px;
}

.email {
  background: url("../images/email.svg") no-repeat left top 2px;
  padding-left: 27px !important;
  background-size: 15px;
}

.address {
  background: url("../images/map-marker.svg") no-repeat left top 1px;
  padding-left: 27px !important;
  background-size: 18px;
}

.item-center {
  align-items: center;
}

.col-4 {
  @include column;
  width: calc(33.3333333333% - 0.5rem);
}

.col-8 {
  @include column;
  width: calc(66.6666666666% - 0.5rem);
}

.col-5 {
  @include column;

  @media screen and (max-width: $desktop) {
    width: calc(49% - 0.5rem);
  }

  @media screen and (min-width: $desktop) {
    width: calc(40% - 0.5rem);
  }

}

.col-7 {
  @include column;

  @media screen and (max-width: $desktop) {
    flex: auto;
  }

  @media screen and (min-width: $desktop) {
    flex: auto;
  }

}

.relative {
  position: relative;
}

.card-claim-details {
  position: relative;

  .carrier-logo {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.fixed-btn {
  position: absolute;
  bottom: 0;
}

.gutter-1 {
  column-gap: $base-space;
}

.row {
  display: flex !important;
  column-gap: 15px;

  &.gutter-0 {
    column-gap: 0px;
  }

  .p-col {
    flex:50%;
  }
}

.row-status {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ($base-space/4);
}

.main {
  padding-left: 92px;
  padding-right: 20px;
  padding-bottom: ($base-space + 1.5);
  padding-top: $base-space;
  min-height: 100%;
}

h3 {
  color: map-get($pilot-colors, "bg2");
  font-weight: 600;
  font-size: 15px;
  margin-bottom: ($base-space/2);
}

.p-btn {
  padding: 5px 10px;
  height: 35px;
  cursor: pointer;
  font-size: 14px;
}

.btn-outline-primary {
  border: 1px solid map-get($pilot-colors, "bg2");
  background-color: white;
  color: map-get($pilot-colors, "bg2");
  border-radius: 5px;
  white-space: nowrap;

}

.title {
  color: map-get($pilot-colors, "bg2");
  margin-bottom: $base-space;
  font-size: 24px !important;
  font-weight: 700 !important;
}

/*icons button*/
.clearIcon {
  position: absolute !important;
  color: map-get($pilot-colors, "bg2") !important;
  z-index: 113;
  right: 52px;
  font-size: 29px;
  bottom: -43px;
  padding: 5px !important;
}

.icon-btn {
  border: none;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;

  img {
    width: 20px;
  }

  &.map-icon {
    position: absolute;
    z-index: 113;
    right: 13px;
    bottom: -42px;

    img {
      width: 25px;
    }
  }

  &.file-icon {
    margin-left: 15px;
    margin-top: 10px;

    img {
      width: 13px;
    }
  }

  &.grid-icon {
    margin-left: 15px;
    margin-top: 10px;

    


  }
}

/* breadcrumb*/

.breadcr-pilot {
  list-style: none;
  padding: 0;
  display: flex;
  font-size: 12px;
  margin-bottom: $base-space;

  li {
    padding: 0 5px 0 0;
    color: map-get($pilot-colors, "bg7");

    a {
      color: map-get($pilot-colors, "bg7");
      text-decoration: none;
    }
  }
}

/* breadcrumb end */

/*Header & Nav start here*/

.header {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  background: map-get($pilot-colors, "bg1");
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 6%);
  z-index: 1000;

  .user-profile {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-bottom: ($base-space + 0.5);
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .logo {
    background-repeat: no-repeat;
    width: 70px;
    height: 40px;
    background-size: 49px;
    background-position: center;
    background-image: url('data:image/svg+xml, #{map-get($svg, "logo")}');
    margin-bottom: $base-space + 1;
    margin-top: $base-space / 2;
  }

  nav {
    ul {
      list-style: none;
      margin: auto;
      display: table;
      padding: 0;
    }

    &.nav {
      .stack-menu-bottom {
        li {
          margin-bottom: $base-space;

          a {
            cursor: pointer;
          }
        }

        left: 50%;
        margin-left: -20px;

        span {
          display: none;
        }

        position: absolute;
        bottom: 0;

        i {
          @include icon-grade;

          &.alert-icon {
            background-image: url('data:image/svg+xml, #{map-get($svg, "alert-icon")}');
          }

          &.settings {
            background-image: url('data:image/svg+xml, #{map-get($svg, "settings")}');
          }
        }
      }

      .primary-menu {
        li {
          margin-bottom: $base-space +.5;
          position: relative;

          &.active {
            &::after {
              content: "";
              height: calc(100% + 20px);
              width: 2px;
              background-color: map-get($pilot-colors, "bg2");
              position: absolute;
              right: -17px;
              top: -10px;
            }
          }
        }

        span {
          display: none;
        }

        i {
          @include icon-grade;

          &.dashboard {
            background-image: url('data:image/svg+xml, #{map-get($svg, "dashboard")}');
          }

          &.claimlist {
            background-image: url('data:image/svg+xml, #{map-get($svg, "claimlist")}');
          }

          &.checklist {
            background-image: url('data:image/svg+xml, #{map-get($svg, "checklist")}');
          }

          &.map-view {
            background-image: url('data:image/svg+xml, #{map-get($svg, "map-view")}');
          }

          &.userslist {
            background-image: url('data:image/svg+xml, #{map-get($svg, "userslist")}');
            background-size: 28px;
          }

          &.carrierlist {
            background-image: url('data:image/svg+xml, #{map-get($svg, "carrierlist")}');
            background-size: 28px;
          }

          &.quryBuilder {
            background-image: url(../images/queryBuilder.svg);
            background-size: 26px;
            position: relative;
            left: 2px;
          }
          &.iconReport {
            background-image: url(../images/icon-report.svg);
            background-size: 21px;
            position: relative;
            left:-2px;
          }

          &.resumes {
            background-image: url('data:image/svg+xml, #{map-get($svg, "resumes")}');
            background-size: 22px;
          }
        }
      }
    }
  }

}

/*PopSettings*/
.SettingsPop {
  z-index: 50000 !important;
  background-color: transparent !important;

  .popWrap {
    background: white;
    padding: 20px 0;
    box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 10%);
    position: fixed;
    bottom: 97px;
    left: 18px;
    width: 230px;
    border-radius: 5px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        cursor: pointer;
        text-decoration: none;
        color: black;
        padding: 10px 20px;
        display: block;
        font-size: 15px;

        &:hover {
          background-color: #eee;
        }
      }

    }


    .users-icon {
      background-image: url(../images/2account-multiple-outline.svg);
      width: 15px;
      height: 14px;
      display: inline-block;
      margin-right: 15px;
      background-repeat: no-repeat;

    }

    .roles-icon {
      background-image: url(../images/clipboard-file-outline.svg);
      width: 15px;
      height: 16px;
      display: inline-block;
      margin-right: 15px;
      background-repeat: no-repeat;
      position: relative;
      top: 3px;

    }

    .help-icon {
      background-image: url(../images/help.svg);
      width: 15px;
      height: 16px;
      display: inline-block;
      margin-right: 15px;
      background-repeat: no-repeat;
      margin-bottom: -3px;
      position: relative;
      left: -2px;
    }

    .impersonate-icon {
      background-image: url(../images/account-heart.svg);
      width: 17px;
      height: 15px;
      display: inline-block;
      margin-right: 15px;
      background-repeat: no-repeat;
      margin-left: -4px;
    }

    .settings-icon {
      background-image: url(../images/sub-settings.svg);
      width: 15px;
      height: 14px;
      display: inline-block;
      margin-right: 15px;
      background-repeat: no-repeat;
      position: relative;
      top: 2px;
    }
  }

}

.profilePop {
  background-color: transparent !important;
  z-index: 50000 !important;

  .popWrap {
    background: white;
    box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 10%);
    position: fixed;
    bottom: 65px;
    left: 30px;
    width: 380px;
    border-radius: 5px;

    .dide {
      &:hover {
        background-color: #f7f8f9;
      }

      border-top: 1px solid #ddd;
      padding:13px 15px;

      &:not(.imp) {
        .MuiInput-underline {
          margin-top: 0px;
        }
      }

      &.imp {
        option {
          padding: 5px;
        }
      }

      .MuiTypography-caption {
        color: #9E9E9E;
      }

      .css-4yua64-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
      .css-1n0mqbp-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
        border-bottom-color: transparent;

        &:hover {
          border-bottom-color: black;
        }
      }

      .MuiNativeSelect-icon {
        font-size: 28px;
        color: black;
      }

      .MuiSelect-icon {
        font-size: 28px;
        color: black;
      }
    }

    .pro-card-footer {
      display: flex;
      column-gap: 15px;
      border-top: 1px solid #ddd;
      padding: 15px;
      justify-content: space-between;
    }

    .userimgwrap {
      text-align: center;
      margin-bottom: 30px;
      padding: 28px 0 10px;

      figure {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 16px;
        margin-top: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      h3 {
        color: black;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}

/*Header & Nav End here*/

.footer {
  padding-left: 110px;
  background-color: white;
  padding-right: 20px;
  padding-bottom: ($base-space / 2 + 0.2);
  padding-top: ($base-space / 2 + 0.2);
  align-content: space-between;
  color: map-get($pilot-colors, "bg6");
  align-items: center;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  margin-top: -37px;

  small {
    white-space: nowrap;

    span {
      font-weight: 700;
      padding-left: 5px;
      color: black;
    }
  }
}

.profile {
  display: grid;
  column-gap: $base-space;
  grid-template-columns: 73px 1fr;

  >div {
    width: 73px;
    height: 73px;
    border-radius: 50%;
    border: 1px solid #ddd;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;

    }
  }
}


.profile-grid {
  display: flex;
  column-gap: $base-space;

  >div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.card {
  background-color: map-get($pilot-colors, "bg1");
  padding: $base-space;
  margin-bottom: $base-space;
  border-radius: 5px;
  color: map-get($pilot-colors, "bg7");

  &.hover {
    cursor: pointer;
  }
}

.stack-top {
  margin-bottom: $base-space;

  &.gridCol2 {
    display: grid;
    grid-template-columns: 350px 1fr;
    column-gap: 20px;
  }

  &.userPro {
    display: grid;
    grid-template-columns: 250px 1fr;

  }
}

/*status bar End here*/
.stack-status-bar {
  padding-top: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin-top: $base-space;
    display: flex;

    li {
      &.active {
        &::before {
          background-color: map-get($pilot-colors, "bggreen") !important;
        }

        &::after {
          background-color: map-get($pilot-colors, "bggreen") !important;
        }
      }

      &.progress {
        &::before {
          background-color: map-get($pilot-colors, "bgyellow") !important;
        }

        &::after {
          background-color: map-get($pilot-colors, "bgyellow") !important;
        }
      }
      &.deleteStat {
        &::before {
          background-color: map-get($pilot-colors, "bgred") !important;
        }

        &::after {
          background-color: map-get($pilot-colors, "bgred") !important;
        }
      }

      position: relative;
      padding:8px 8px 0 8px;
      text-align: center;
      flex: auto;

      &::before {
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        background-color: map-get($pilot-colors, "bg3");
        top: -6px;
        left: 0;
        display: block;
      }

      &::after {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        background-color: map-get($pilot-colors, "bg3");
        top: -11px;
        left: 50%;
        display: block;
        border-radius: 50%;
        margin-left: -10px;
      }
    }
  }
}

/*status bar End here*/

.stats-profile {
  display: flex;
  column-gap: 10px;
  align-items: flex-end;

  .stats-block {
    &:nth-child(2) {
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      padding: 0 10px;
    }

    flex: 1;
    text-align: center;

    p {
      font-size: 12px;
    }

    h3 {
      font-size: 21px;
      font-weight: 700;
    }
  }
}
.col2-grid-task {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
}

.stack-task {
  

  >div {
    color: black;

    flex: calc(50% - 1rem);
    margin-bottom: $base-space;

    label {
      color: map-get($pilot-colors, "bg2");
      margin-bottom:0.3rem;
      display: block;
    }
  }
}
.list-data-2 {
  display: grid;
  grid-template-columns: 165px 1fr;
  grid-gap:10px;
  list-style: none;
  align-items: flex-start;
  li {
    padding: 0px;
  }
}

.list-data {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: 131px 1fr;  
  align-items: flex-start;
  li {   
    color: map-get($pilot-colors, "bg7");
    padding: 0px;
    &.text-label {     
      font-weight: 600;
      color: black;
    }

    margin-bottom: ($base-space/2);
  }
}

.list-data3 {
  list-style: none;
  padding: 0;  
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  grid-template-columns: 165px 1fr;
    row-gap: 10px;
    column-gap: 10px;
    display: grid;
    align-items: flex-start;
 

  li {
    width:172px;
    color: map-get($pilot-colors, "bg7");
    padding: 0px;

    &.text-label {
      width: 50%;
      font-weight: 600;
      color: black;
    }

    margin-bottom: ($base-space/2);
  }
}

.tasksla-title {
  border-bottom: 1px solid map-get($pilot-colors, "bg3");
  padding-bottom: 10px;

  a {
    font-size: 13px;
  }
}

.task-sla-grid {
  max-height: 300px;
  overflow: auto;

  .grid-row {
    &.title-grid {
      >div {
        font-weight: 600;
      }
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 7px 0;
    color: black;
  }
}

.note-card {
 display: grid;
 grid-template-columns: 311px 1fr 162px;
  padding: 0px 15px 5px 0;
  h4{
    font-weight:700;
  }

  >div {
    padding: 0 15px;
    &:first-child {      
      border-right: 1px solid map-get($pilot-colors, "bg3");
    }

    &:last-child {
      margin-left: auto;
    }

  }
}

.document-card {
  @include table-compo;
  /*border: 1px solid map-get($pilot-colors, "bg3");  */
  margin-bottom: 10px;

  >div {
    &:first-child {
      width: 25%;
      border-right: 1px solid map-get($pilot-colors, "bg3");
    }

    @include table-cell;
  }

  .status-mark {
    position: absolute;
    right: 0;
    bottom: -15px;
  }
}

.tasks-card {
  @include table-compo;
  border: 1px solid map-get($pilot-colors, "bg3");
  padding: 10px 0px;
  margin-bottom: $base-space;

  .task-processs {
    display: grid;
    grid-template-columns: 220px 140px 140px 1fr;
    column-gap: 15px;
    i {
      position: relative;
      top: 6px;
      margin-right: ($base-space/2);
    }

    >div {
      &:not(:last-child) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  >div {
    &:last-child {
      border-left: 1px solid map-get($pilot-colors, "bg3");
      width: 17%;
    }

    &:first-child {
      width: 20%;
      border-right: 1px solid map-get($pilot-colors, "bg3");
    }

    @include table-cell;
  }

  .task-status-mark {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.forms-card {
  display: flex;
  border: 1px solid map-get($pilot-colors, "bg3");
  padding: 10px 15px;
  margin-bottom: $base-space;
  border-radius: 5px;
  align-items: center;

  >div {
    &:last-child {
      margin-left: auto;
    }
  }
}

fieldset {
  &.xat {
    border-color: #01b5d8;

    legend {
      color: #01b5d8;
      font-weight: 500;
      font-size: 13px;
    }
  }

  &.intern {
    border-color: #8fbe6d;

    legend {
      color: #8fbe6d;
      font-weight: 500;
      font-size: 13px;
    }
  }

  margin-bottom: 0.2rem;

  legend {
    padding: 0 7px;
    margin-left: 15px;
  }

  border: 1px solid map-get($pilot-colors, "bg3");
  border-radius: 5px;
}

/*Pre-loading Bar start*/

.progress-materializecss {
  margin: 3px 0;
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #e2ecfe;
  border-radius: 2px;
  overflow: hidden;
}

.progress-materializecss .indeterminate {
  background-color: #3fddb7;
}

.progress-materializecss .indeterminate:before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress-materializecss .indeterminate:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 1.15s;
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

/*Pre-loading Bar End*/

/* Claim Dashboard start */

.dashboard-status-block {
  &.rightNone {
    >div {
      background: #fff url(../images/arrow-right-2.png) no-repeat right center !important;


    }

    .block-list {
      &.active {
        &:first-child {
          &::after {
            width: calc(100% - 30px);
            left: 1px;
          }
        }

        h3 {
          color: #337dff;
        }

        &::after {
          background-color: #337dff;
          height: 3px;
          width: calc(100% - 12px);
          left: -16px;
        }
      }

      &:last-child {
        background: #fff url(../images/last-child-arrow-2.png) no-repeat right center !important;
      }
    }
  }

  @media only screen and (max-width: 1222px) {
    &.taskStat {
      &.noRightcolor {
        >div {
          background: #fff url(../images/arrow-right-small-2.png) no-repeat right center !important;

          &:last-child {
            background: #fff url(../images/last-child-arrow-2.png) no-repeat right center !important;
          }

          &:first-child {
            &::before {
              width: calc(100% - 18px) !important;
            }

            &::after {
              width: calc(100% - 18px) !important;
            }
          }

          &::before {
            width: calc(100% - 4px);
          }

          &::after {
            width: calc(100% - 4px);
          }

        }

      }

      .block-list {
        grid-template-columns: 1fr !important;

        >div {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1222px) and (max-width: 1361px) {
    &.taskStat {
      &.noRightcolor {
        >div {
          background: #fff url(../images/arrow-right-small-2.png) no-repeat right center !important;

          &:last-child {
            background: #fff url(../images/last-child-arrow-2.png) no-repeat right center !important;
          }

          &:first-child {
            &::before {
              width: calc(100% - 18px) !important;
            }

            &::after {
              width: calc(100% - 18px) !important;
            }
          }

          &::before {
            width: calc(100% - 4px);
          }

          &::after {
            width: calc(100% - 4px);
          }

        }

      }

      .block-list {
        label {
          font-size: 9px !important;
          font-weight: 600 !important;
        }
      }
    }

  }

  @media screen and (max-width: $medium) {

    &.taskStat {
      &.noRightcolor {
        >div {
          background: #fff url(../images/arrow-right-small-2.png) no-repeat right center !important;

          &:last-child {
            background: #fff url(../images/last-child-arrow-2.png) no-repeat right center !important;
          }

          &:first-child {
            &::before {
              width: calc(100% - 18px) !important;
            }

            &::after {
              width: calc(100% - 18px) !important;
            }
          }

          &::before {
            width: calc(100% - 4px);
          }

          &::after {
            width: calc(100% - 4px);
          }

        }

      }

      .block-list {
        background: #fff url(../images/arrow-right-small.png) no-repeat right center;
        padding: 4px 15px 4px 0;

        &:first-child {
          padding-left: 5px;
        }

        display: flex;
        justify-content:center;

        &:last-child {
          padding-right: 15px !important;
        }

        flex:auto;

        &:last-child {
          background: #fff url(../images/last-child-arrow.png) no-repeat right center;
        }

        label {
          font-size: 11px;
          font-weight: 500;
        }
      }

    }
  }

  .yellow-stat {
    color: #FFB507;
    font-weight: 600;
    padding-bottom: 3px;
  }

  .red-stat {
    color: #D5001A;
    font-weight: 600;
  }

  display: flex;
  text-align: center;
  justify-content: space-between;

  &:not(.taskStat) {

    >div {
      &:last-child {
        padding-right: 0px;
      }

      padding-right: 34px;
    }

  }

  &.taskStat {

    &.noRightcolor {
      >div {
        background: #fff url(../images/arrow-right-small-2.png) no-repeat right center !important;

        &:last-child {
          background: #fff url(../images/last-child-arrow-2.png) no-repeat right center !important;
        }

        &:first-child {
          &::before {
            width: calc(100% - 18px) !important;
          }

          &::after {
            width: calc(100% - 18px) !important;
          }
        }

        &::before {
          width: calc(100% - 4px);
        }

        &::after {
          width: calc(100% - 4px);
        }

      }

    }

    >div {
      &.active {
        &:first-child {
          &::after {
            width: calc(100% - 25px);
            left: 1px;
          }
        }

        h3 {
          color: #337dff;
        }

        &::after {
          background-color: #337dff;
          height: 3px;
          width: calc(100% - 8px);
          left: -16px;
        }
      }

      &:first-child {

        &::before {
          width: calc(100% - 23px);
        }

        &::after {
          width: calc(100% - 23px);
        }
      }

      &:after {
        left: -14px;
        width: calc(100% - 8px);
      }

      &::before {
        left: -14px;
        width: calc(100% - 8px);

      }

      label {
        color: #959595;
        font-weight: 500;
        font-size: 11px;
        white-space: nowrap;
        max-width: 100%;
        display: block;
        text-overflow: ellipsis;
      }

      background:#fff url(../images/arrow-right-small.png) no-repeat right center;
      padding:7px 19px 7px 0;

      &:last-child {
        padding-right: 15px;
      }
    }

    .block-list {
      display: grid;
      grid-template-columns: 1fr 28px;
    }

    &.inspTop {
      .block-list {
        grid-template-columns: 1fr;
      }
    }

  }

  margin-bottom: 10px;

  >div {
    position: relative;
    padding: 9px 0;

    h3 {
      margin-bottom: 0px;
      color: black;
      font-weight: 700;
      font-size: 18px;

    }

    label {
      color: #000;
      font-weight: 300;

    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -14px;
      bottom: 0px;
      height: 1px;
      background-color: #dbdbdb;
      width: calc(100% - 14px);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -14px;
      top: 0px;
      height: 1px;
      background-color: #dbdbdb;
      width: calc(100% - 14px);
    }

    &:last-child {
      background: #fff url(../images/last-child-arrow.png) no-repeat right center;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -14px;
        top: 0px;
        height: 1px;
        background-color: #dbdbdb;
        width: calc(100%) !important;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: -14px;
        bottom: 0px;
        height: 1px;
        background-color: #dbdbdb;
        width: calc(100%) !important;
      }

    }

    &:first-child {
      border-left: 1px solid #ddd;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 1px;
        background-color: #dbdbdb;
        width: calc(100% - 25px);
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 1px;
        background-color: #dbdbdb;
        width: calc(100% - 25px);
      }
    }

    background:#fff url(../images/arrow-right.png) no-repeat right center;
    flex: auto;
  }

}

/*Claim Grid*/
.claims-list-grid {
  background-color: white;

  .k-toolbar.k-grid-toolbar {
    overflow: inherit;

    &+.k-toolbar.k-grid-toolbar {
      order: 1;
      position: absolute;
      right: $base-space;
      bottom: 6px;
      z-index: 1;
      background-color: transparent;
      padding: 0;
      border: none;

      .icon-btn {
        margin-left: $base-space;
      }
    }

  }

  .k-pager-info {
    padding-right: 66px;
  }

  

}

/*Claim Grid end*/

/* Claim Dashboard end */

/* Carrier Dashboard Start */

.carrier-list-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: $base-space;
}

.carrier-list-stack {
  h3 {
    color: black;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: .5rem;
  }

  figure {
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 90px;
    height: 90px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: grid;
  grid-template-columns: 100px auto;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;
      padding: 0px;
    }
  }

  .xact-sec {
    display: flex;
    margin-bottom: 10px;

    .bold {
      font-weight: 700;
    }

    >div {
      padding: 0 10px;

      &:first-child {
        border-right: 1px solid #ddd;
        padding-left: 0px;
      }

      &:last-child {
        .bold {
          color: black;
        }
      }
    }
  }
}

/* Carrier Dashboard end */

/*carrier profile start*/
.lastupdated {
  margin-bottom: 10px;

  .refresh-icon {
    position: relative;
    margin-left: 8px;

    &.hover {
      cursor: pointer;
    }
  }
}

.grid-carrier-profile-stack1 {
  .carrier-list-stack {

    .add-image {
      position: absolute;
      bottom: 0px;
      right: 30px;
    }

    grid-template-columns: 154px auto;

    figure {
      width: 130px;
      height: 130px;

      >img {
        width: 80%;

      }
    }

    .light-green {
      font-weight: 700;

      span {
        position: relative;
        top: -5px;
      }
    }

    .text-bold {
      color: black;
    }
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: $base-space;
  margin-bottom: $base-space;

  .refresh-icon {
    position: absolute;
    left: 10px;
    top: 10px;

    &.hover {
      cursor: pointer;
    }
  }

  .label-class {
    font-weight: 700;
    text-transform: uppercase;
    color: #333;
    font-size: 14px;
  }

  .border-blue {
    border: 1px solid #e1e1e1;
    position: relative;
    border-bottom: 7px solid #01b5d8;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    align-items: flex-end;
    &.active{
      box-shadow: rgb(0 95 237 / 16%) 0px 1px 4px, rgb(6 108 235) 0px 0px 0px 1px;
      &::after{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-color:#EFF4FC;
        transform: rotate(45deg);
        position: absolute;
        bottom: -33px;
        left: 50%;
        margin-left: -10px;
        box-shadow: inset 1px 1px 0px 0px rgba(0,0,0,0.07);
      }
    }

    h1 {
      font-weight: 700;
      font-size: 55px;
      color: black;
      cursor: pointer;
    }

    h6 {
      cursor: pointer;
    }

  }

  .border-green {
    border: 1px solid #e1e1e1;
    border-bottom: 7px solid #8fbe6d;
    border-radius: 5px;
    padding: 15px;
    position: relative;
    display: flex;
    align-items: flex-end;
    &.active{
      box-shadow: rgb(0 95 237 / 16%) 0px 1px 4px, rgb(6 108 235) 0px 0px 0px 1px;
      &::after{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-color:#EFF4FC;
        transform: rotate(45deg);
        position: absolute;
        bottom: -33px;
        left: 50%;
        margin-left: -10px;
        box-shadow: inset 1px 1px 0px 0px rgba(0,0,0,0.07);
      }
    }

    h1 {
      font-weight: 700;
      font-size: 55px;
      color: black;
      cursor: pointer;
    }

    h6 {
      cursor: pointer;
    }

  }
}
.box-total-boxex{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,0.07);
  column-gap: 15px;
  row-gap: 15px;
  background: #EFF4FC;
  margin-left: -17px;
  margin-right: -17px;
  padding:25px;
  .box-total-boxex-body{
    .grid-carrier {
      background: #fff;
    }
    
  }
}
.cycleBox-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
}
.grid-carrier {
  position: relative;

  border: 1px solid #e1e1e1;
  border-bottom: 5px solid #89a0f9;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  &.borderBottom1x {
    border-bottom: 1px solid #e1e1e1!important;
    h4 {
     font-size:1.5rem;
    }
  }
  &.cyclebox{
    .cycleboxBody{
      
      > div{
        display: flex;
        justify-content: center;
        column-gap: 4px;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 10px;
        b{
          font-size: 1.5rem;
        }
        span{
          font-size: .8rem;
        
        }
        
      }
    }
    small {
      position: absolute;
      bottom: 4px;
      right:8px;
      color:#4a4a4a;
    }
  }

  h4 {
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
  }

  h6 {
    text-transform: uppercase;
    color: #333;
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;

  }

  button {
    position: absolute;
    left: 7px;
    top: 7px;
  }

}

/*carrier profile end*/

/*Roll Manage Start*/
.mainTitle {
  >th {
    font-weight: 700;
  }
}

.subTitle {
  >th {
    padding-left: 30px;
  }
}

/*Roll Manage End*/

.model-footer {
  padding: 15px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  margin-top: 30px;
  border-top: 1px solid #ddd;
}

.manageroll-container {
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;

}

.saveBtn {
  margin-left: auto !important;

}

.grid-cover {
  margin-bottom: 30px;
}

/*Mapview Page start here*/

.claimsListChkbox {
  position: absolute !important;
  top: 6px;
  left: 1px;
}

.slaStats {
  position: absolute;
  top: 15px;
  right: 12px;
}

.userMapixon {
  margin-top: 7px;
  position: absolute;
  left: 12px;
  top: 10px;
}



.alignIcon {
  padding-left: 30px !important;
  padding-bottom: 0px !important;
  min-height: inherit !important;
  text-align: left !important;
  align-items: flex-start !important;
  width: 50%;

  svg {
    position: absolute;
    left: 0px;
    font-size: 22px;
  }

}

/*Map view End*/


/*User Profile Style*/
.user-profile-page {
  margin-bottom: 15px;

  .card-claim-details {
    h2 {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .pic {
    margin: auto;
    border-radius: 50%;
    position: relative;
    width: 92px;
    height: 92px;
    border: 3px solid #337dff;
    overflow: hidden;

    img.userImage {

      width: 100%;
    }

  }

  .add-image {
    position: absolute;
    bottom: -3px;
    right: 10px;
  }

  .dashboard-status-block {


    &.user-profile-status-block {
      grid-template-columns: 150px 200px 239px 185px 197px;
      margin-left: auto;

      .block-list {
        &.active {
          &:first-child {
            &::after {
              width: calc(100% - 30px);
              left: 1px;
            }
          }

          h3 {
            color: #337dff;
          }

          &::after {
            background-color: #337dff;
            height: 3px;
            width: calc(100% - 12px);
            left: -16px;
          }
        }

        >div {
          h3 {
            font-size: 21px;
            height: 31px;

          }

          .yellow-stat {
            height: 31px;
            line-height: 34px;
          }

          .red-stat {
            height: 31px;
            line-height: 34px;
          }

          label {
            height: 31px;
            line-height: 34px;
            font-weight: normal;

            &::before {
              left: -12px;
            }
          }
        }
      }
    }


  }

  .insProfile {
    position: relative
  }

  .send-msg {
    padding: 7px 10px;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.userProfileGrid {
  display: grid;
  grid-template-columns: 157px 1fr;
  column-gap: 15px;
}

/*updated for map view*/
.boxBorder {
  border: 1px solid #fff;
}

.clmPopGrid-3 {

  padding-bottom: 10px;
  display: flex;
  column-gap: 1.5rem;

  &.autogrid {
    >div {
      flex: auto;
    }
  }

  >div {
    h6 {
      font-size: 15px;
    }

    flex: 1;

    span {
      color: #000;
      white-space: nowrap;
    }
  }
}

.clmPopGrid-all {
  display: flex;
  border-top: 1px solid #eee;
  padding-top: 15px;
  padding-bottom: 10px;
  column-gap: 1rem;
  flex-wrap: wrap;
  padding-right: 29px;
  h4 {
    font-weight: 700;
  }

  .MuiSvgIcon-fontSizeMedium {
    font-size: 22px;
  }

  &.inheritflex {
    >div {
      flex: 1;
    }
  }

  >div {

    flex: auto;

    span {
      color: #000;
      white-space: nowrap;
    }
  }
}

.btnPosition {
  position: absolute !important;
  right: 12px;
  bottom: 15px;
  font-size: 19px;
}

.gr-2 {
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
}

.grid-row {
  display: grid !important;
  grid-template-columns: 1fr 2fr;
  column-gap: 1rem;
  row-gap: 1rem;

}

.inspectorCalender {
  height: 100% !important;
  max-height: calc(100vh - 239px);

  .k-scheduler-agendaday {
    font-size: 15px;
    line-height: 1.8;
    font-weight: 600;
  }

  .k-event,
  .k-current-time {
    background-color: #01B5D8;
  }

  .k-toolbar {
    background-color: #eee;
  }

  .k-button-solid-base.k-selected {
    border-color: map-get($pilot-colors, "bg2");
    color: white;
    background-color: map-get($pilot-colors, "bg2");
  }

}

.carrier-edit {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  background-color: white;
  padding: 2rem;
  &.setshadow {
    
      
      box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.05);
      margin-bottom: 4px;
      margin-top: 15px;
  
  }
}

.box1 {
  margin-bottom: 20px;

  label {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 15px 20px;

    span {
      padding-left: 10px;
      display: inline-block;
      font-weight: 600;
    }
  }

}

.treelist {
  position: relative;

  h4 {
    background-color: white;
    position: relative;
    z-index: 100;
    top: 6px;
    padding: 12px 0;
    font-weight: 600;

  }

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    background-color: #ddd;
    position: absolute;
    left: 21px;
    top: 0;
  }

  .historyTab {
    .update-status {
      position: absolute;
      right: 8px;
      top: 5px;
      color: #000;
      font-size: 12px;

    }

    border: 1px solid #ddd;

    .MuiGrid-container {
      div {

        &:nth-child(2),
        &:last-child {
          border-left: 1px solid #ddd;
          padding-left: 15px;
        }
      }
    }

    padding:7px 15px;
    margin-left: 70px;
    margin-top: 20px;
    position: relative;

    span {
      position: absolute;
      background-color: white;
      z-index: 100;
      top: 10px;
      left: -70px;
      font-size: 12px;
      color: #000;
      font-weight: 200;
    }

    svg {
      position: absolute;
      z-index: 100;
      top: 45px;
      left: -60px;
      background-color: white;
    }
  }
}

.filetrTop {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 18px;
  row-gap: 18px;
  padding: 25px;
  background-color: #fff;
  margin-bottom: 10px;
}

.iconContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  right: 5px;
  top: 5px;

  svg {
    color: #337dff;
    font-size: 24px;
  }
}

.Document {
  position: relative;
}

.profileGrid {
  display: grid;
  grid-template-columns: 1fr 206px;
}

.mapviewFilter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 35px;
  margin-bottom: 15px;
  column-gap: 7px;

  >div {
    flex: auto;
  }
}

.mapviewContainer {
  min-height: calc(99vh - 165px);
  
}

.gridoth-login {
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: baseline;
  column-gap: 10px;

  .MuiIconButton-sizeMedium {
    font-size: 22px;
    color: #337dff;
  }
}

.claim-info-poup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 18px;
  row-gap: 18px;
  .span2 {    
    grid-column: span 2;
  }
}

.gridCol2m {
  display: grid;
  grid-template-columns: 54% 45%;
  column-gap: 15px;
  height: calc(100vh - 200px);
}

.toppopHeader {
  display: grid;
  grid-template-columns: auto 1fr 1fr;

  >div {
    padding-right: 20px;
  }

  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.ispectorGrid {
  max-height: calc(100vh - 240px);
  min-height: calc(100vh - 240px);
}

.rolesTable {
  margin-bottom: 15px;

  .css-qfupkp-MuiTableCell-root {
    font-weight: 700;
    font-size: 14px;
  }

  .css-1c09v96-MuiTableCell-root {
    padding: 8px 15px;
  }

}

.custom-scrollbar::-webkit-scrollbar {
  height: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #abc9ff !important;
}


#questionnaire {
  .attach-icon {
    background-image: url(../images/attach.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
  }

  .close-icon {
    background-image: url(../images/close.svg);
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    padding-top: 10px;
  }

  .multi-select {
    width: 100%;
  }
}

.claimtaskGrid {
  display: flex;
  justify-content: space-between;

  .MuiRadio-root {
    &.Mui-checked {
      &+span {
        color: #337dff;
        font-weight: 700;
      }

    }
  }

  .MuiFormControlLabel-root {
    background: #fff;
    padding: 0px 15px 0 2px;
    border-radius: 5px;
    margin-left: 0px;
    box-shadow: 1px 1px 20px 0px rgb(0 0 0 / 6%);
    margin-right: 10px;
  }
}

.k-animation-container-relative {
  z-index: 10000 !important;

  input[type="checkbox"] {
    margin-right: 8px;
  }
}

.MuiCheckbox-root,
.MuiRadio-root {
  font-size: 22px;
}

.k-column-title {
  overflow: initial !important;
}

.MuiModal-root {
  z-index: 60000 !important;
}

.MuiTooltip-popper,
.k-animation-container-fixed,
.k-animation-container {
  z-index: 150000 !important;
}

/*For Resume builder*/
#resume-builder-workspace {
  margin-left: -15px;
  margin-top: -15px;
  margin-right: -15px;
  // margin-left: -20px;
  // margin-top: - $base-space;
  // margin-bottom: -5px;
  // margin-right: -20px;
  //padding-left: 50px;

  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #877f7f32;
    border-radius: 3px;

    &:hover {
      background: #00000032;
    }
  }

  .k-expander {
    background-color: transparent;
    border-color: transparent;
    border-width: 0;
  }

  .expander.k-focus {
    background-color: transparent;
    border-color: transparent;
    border-width: 0;
  }

  .k-expander-header {
    padding: 0;
  }

  .k-expander-header:hover {
    background-color: transparent;
  }

  .k-expander-content {
    // padding: 0;
    padding: 0px 16px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .questionnaire-checkbox {
    width: 17px;
    height: 17px;
  }

  .css-repsar-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;
  }

  .k-animation-container-relative {
    position: relative;
    display: block;

  }

  .css-1o43ar-MuiGrid-root {
    padding: 0px !important;
  }

  .search-param-checkbox {
    width: 15px;
    height: 15px;
  }

  .chekboxSidebar {
    color: #8b8585;

    .MuiCheckbox-root {
      padding: 0px;
      margin-right: 10px;
    }
  }

}

.gridRsbuilder {

  i.iconPdf {
    background-image: url('data:image/svg+xml, #{map-get($svg, "pdf")}');
    background-size: 17px;
    display: block;
    width: 20px;
    height: 24px;
    background-repeat: no-repeat;
  }

  .leftMenupanel {
    position: fixed;
    left: 72px;
    top: 0;
    bottom: 0;
    max-width: 336px;
    width: 336px;

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }

}

.resGrid {
  display: flex;
  justify-content: space-between;

  .statRes {
    display: flex;
    column-gap: 15px;
    padding-bottom: 15px;

    >div {
      background: #fff;
      display: flex;
      align-items: flex-end;
      padding: 15px 15px;
      column-gap: 15px;
      border-radius: 5px;


      span {
        margin-bottom: 5px;
        color: #000;
        padding-right: 25px;
      }

      h1 {
        font-weight: 800;
        font-size: 30px;
      }
    }
  }
}

.grid-cl-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;  
  row-gap: 15px;
  margin-bottom:15px;
}

.grid-cl-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
  margin-bottom: 15px;
}

.checkBox-cols {
  display: grid !important;
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 15px;


}

.flexGroup {
  .repeatGrid {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 3%);
    margin-bottom: 1px;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 250px;
    row-gap: 15px;
    column-gap: 15px;

    &.orshow {
      margin-left: 35px;
    }

    &.andshow {
      margin-left: 35px;
    }

    &.andshow,
    &.orshow {
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #ddd;
        left: -21px !important;
        top: 0;
        left: 0;
      }
    }

  }



}

.flexButton {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  align-items: flex-start;

  button {
    flex: auto;

  }
}

.swichCustom {
  .active {
    color: #337dff;
    font-weight: 600;
  }

  .MuiSwitch-thumb {
    background-color: #337dff !important;
  }

  .MuiSwitch-track {
    background-color: #aba6a6 !important;
  }
}

.col3-repeat {
  .MuiFormGroup-root{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr ;
 
  column-gap: 15px;
}
}
.css-j5h6pi-MuiPopper-root,.css-1mtsuo7{
  z-index: 60000!important;
}
.select__menu {
  z-index: 70000!important;
}
.mapTopfilter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  margin-bottom: 15px;
  width: 65%;
}
.decliner {
  display: -webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.Mui-error {
  fieldset {
    border-color: #d32f2f !important;
  }
}

.validateClass {
  border: 1px solid red !important;
  border-radius: 5px;
}

#custom-date-picker-container {
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #877f7f32;
    border-radius: 3px;

    &:hover {
      background: #00000032;
    }
  }

  .calendar-container {
    display: flex;
    height: 280px;
  }

  .calendar-left-menu {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #aeaeae;
    background: #fff;
    max-height: 100%;
    padding: 10px;
    min-width: 200px;
    overflow: auto;
  }

  .k-expander-content {
    padding: 0;
  }

}
.grid-Row33 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.Mui-selected {
  .legendCount {
    background-color:#1976d2;
    color: #fff;
  }
}
.legendCount {
  margin: 0px;
    background-color:#bdc5d5;
    width: 19px;
    height: 19px;
    line-height: 18px;
    margin-top: -26px;
    margin-left: 0px!important;
    color: #282121;
    border-radius: 50px;
    font-weight: 400;
    font-size: 10px;
}
.profileTabs {
 
  button {
    padding-bottom: 0;
    padding-top: 20px;
    &:not(:last-child) {
      padding-right: 0px;
    }
    min-height:auto;
    
    
  }
}
.alertNew {
  position: absolute;
  bottom:74px;
  z-index: 10000;
  width: calc(100% - 48px);
  right: 22px;
  left: 22px;
  box-shadow: 1px 2px 7px rgb(0 0 0 / 11%);
}
.searchinput {
  
   input{
   padding:5.5px 9px;
   background-image: url(../images/search.svg);
   background-position: left 10px center;
   padding-left: 30px;
   background-repeat: no-repeat;
   }
}
.titleTable {
  th {
    font-weight: 700;
    font-size: 15px;
  }
}
.k-dialog-wrapper{
  z-index: 90000 !important;
}

/*Audit trail Grid*/
.audit-trail-list-grid {
  background-color: white;

  .k-toolbar.k-grid-toolbar {
    overflow: inherit;

    &+.k-toolbar.k-grid-toolbar {
      order: 1;
      position: absolute;
      right: $base-space;
      bottom: 6px;
      z-index: 1;
      background-color: transparent;
      padding: 0;
      border: none;

      .icon-btn {
        margin-left: $base-space;
      }
    }

  }

  .k-pager-info {
    padding-right: 66px;
  }

  

}
.gridMob {
  display: grid;
  grid-template-columns:480px 1fr;
  column-gap: 15px;
}
.infomapview {
  &+div{
    height: calc(100% - 58px)!important;
  }
  display: flex;
  padding-bottom: 15px;
  grid-template-columns: auto auto auto;
  >div {
    &:not(:last-child){
      border-right: 1px solid #ddd;
      padding-right: 15px;
    }
    &:not(:first-child){
      padding-left: 15px;
    }
  }
}